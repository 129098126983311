import React, { useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import Axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import Recaptcha from 'react-google-recaptcha'
import { isBrowser } from '../../utils/isBrowser'
import ClearIcon from '@mui/icons-material/Close'
import { Add } from '@mui/icons-material'

import { formatRangeWithTime } from '../../utils/date'
import { formatCurrency } from '../../utils/currency'
import TextField from './TextField'
import HeadlineTag from './HeadlineTag'
import RichText from './RichText'
import Checkbox from './Checkbox'
import Select from './Select'
import Button from './Button'
import BadgeDividerBlock from '../blocks/BadgeDividerBlock'
import { useInputState } from '../../hooks/useInputState'
import { cn } from '../../helper'
import { mapRequestData, trainingMapping } from '../../utils/form'

const FormTraining = ({
	name,
	className,
	terms,
	redirectUri,
	submitLabel,
	headlineOrder,
	dates,
	price,
	anchor,
	eventInformation,
}) => {
	const now = new Date()
	const recaptchaSiteKey = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY
	const recaptchaEnabled =
		isBrowser && _.isString(recaptchaSiteKey) && recaptchaSiteKey !== ''
	const { t, i18n } = useTranslation()
	const formName = name + '-'

	const [isPending, setPending] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [pendingMessage, setPendingMessage] = useState(null)
	const { language } = i18n

	const dateOptions = dates
		? dates
			// Filter old dates
			.filter((date) => {
				const endDateTime = new Date(date.endDate).getTime()
				return endDateTime > now.getTime()
			})
			// Sort dates ASC past/today/future
			.sort((a, b) => {
				return new Date(a.startDate) - new Date(b.startDate)
			})
			.map((date) => {
				const text = formatRangeWithTime({
					startDate: new Date(date.startDate),
					endDate: new Date(date.endDate),
					locale: language,
				})
				return {
					text,
					value: text,
				}
			})
		: []

	const [participants, setParticipants] = useInputState([
		{ id: uuidv4(), name: '', email: '' },
	])
	const [contactParticipates, setContactParticipates] = useInputState(false)
	const [givenName, setGivenName] = useInputState('')
	const [familyName, setFamilyName] = useInputState('')
	const [organization, setOrganization] = useInputState('')
	const [email, setEmail] = useInputState('')
	const [phone, setPhone] = useInputState('')
	const [address, setAddress] = useInputState('')
	const [addressAddition, setAddressAddition] = useInputState('')
	const [postalCode, setPostalCode] = useInputState('')
	const [city, setCity] = useInputState('')
	const [trainingDate, setTrainingDate] = useInputState(
		dateOptions && dateOptions?.length > 0 ? dateOptions[0].value : null,
	)
	const recaptchaRef = useRef(null)

	const handleSubmit = (event) => {
		event.preventDefault()
		if (isPending) return
		setErrorMessage(null)
		setPendingMessage(null)

		const recaptcha = recaptchaRef.current?.getValue()

		if (recaptchaEnabled && !recaptcha) {
			setErrorMessage(t('Form.error.recaptchaPending'))
			return
		}

		const data = {
			eventInformation,
			language,
			givenName,
			familyName,
			organization,
			email,
			phone,
			recaptcha,
			address,
			addressAddition,
			postalCode,
			city,
			trainingDate,
			contactParticipates,
			participants,
		}
		sendData(data)
	}

	const sendData = async (data = {}) => {
		setPending(true)
		setPendingMessage(t(`Form.pendingMessage.contact`))
		try {
			const requestData = mapRequestData({ mapping: trainingMapping, data })
			await Axios.post('/api/relution-training-form', requestData)
			console.log(requestData)
			navigate(redirectUri)
		} catch (error) {
			setErrorMessage(t('Form.error.registrationUnkown'))
			console.warn(error)
		} finally {
			setPending(false)
		}
	}

	const toggleContactAsParticipant = (state) => {
		setContactParticipates(state)
		if (!state && participants.length === 0) {
			addParticipant()
		}
		if (
			state &&
			participants.length === 1 &&
			participants[0] &&
			(participants[0].name === '' || participants[0].email === '')
		) {
			setParticipants([])
		}
	}

	const addParticipant = () => {
		const newParticipant = { id: uuidv4(), name: '', email: '' }
		setParticipants([...participants, newParticipant])
	}

	function changeParticipant(id, field) {
		const updatedParticipants = participants.map((participant) => {
			if (id === participant.id) {
				return { ...participant, [field.name]: field.value }
			}
			return participant
		})
		setParticipants(updatedParticipants)
	}

	function deleteParticipant(id) {
		const remainingParticipants = participants.filter(
			(participant) => id !== participant.id,
		)
		if (remainingParticipants.length > 0 || contactParticipates) {
			setParticipants(remainingParticipants)
		}
	}

	const [totalParticipants, costs, costsFormatted] = useMemo(() => {
		const num = participants.length + Number(contactParticipates)
		const costs = num * price
		return [
			num,
			costs,
			formatCurrency({
				value: costs,
				language,
			}),
		]
	}, [participants, contactParticipates, language])

	function recaptchaOnExired(value) {
		setErrorMessage(t('Form.error.recaptchaExpired'))
	}

	function recaptchaOnErrored(value) {
		setErrorMessage(t('Form.error.recaptchaNotLoaded'))
	}

	function recaptchaAsyncScriptOnLoad(value) {
		if (!value.loaded && value.loaded != undefined) {
			setErrorMessage(t('Form.error.recaptchaNotLoadedAsync'))

		} else if (!value.errored && value.errored != undefined) {
			setErrorMessage(t('Form.error.recaptchaNotLoadedAsync') + '(Errored:' + value.errored + ')');

		} else {
			//Script has been loaded. Hide the error message
			setErrorMessage(null)
		}

	}

	return (
		<form
			id={anchor}
			autoComplete="on"
			onSubmit={handleSubmit}
			className={cn('box grid gap-x-15 gap-y-24 lg:grid-cols-12', className)}
		>
			<div className="min-w-0 space-y-24 lg:col-span-7">
				<div className="space-y-15">
					<HeadlineTag order={headlineOrder}>
						<BadgeDividerBlock
							as="span"
							className="block"
							text={t('Events.training.formHeadline')}
						/>
					</HeadlineTag>
					<HeadlineTag
						order={headlineOrder + 1}
						className="text-18 uppercase font-bold"
					>
						<span>{t('Events.training.formDateHeadline')}</span>
					</HeadlineTag>
					<div className="!mt-4 grid gap-x-5 gap-y-4 md:grid-cols-2">
						<div className="sm:col-span-2 sm:pr-1/3">
							{/* <Select
                labelHidden={true}
                label={t('Form.label.trainingDate')}
                name="trainingDate"
                value={trainingDate}
                onChange={setTrainingDate}
                options={dateOptions}
                required={true}
              /> */}
							<select
								label={t('Form.label.trainingDate')}
								name="trainingDate"
								value={trainingDate}
								onChange={(e) => setTrainingDate(e.target.value)}
								required
							>
								{dateOptions?.map((item) => (
									<option key={item?.value} value={item?.value}>
										{item?.text}
									</option>
								))}
							</select>
						</div>
					</div>
					<HeadlineTag
						order={headlineOrder + 1}
						className="text-18 uppercase font-bold"
					>
						<span>{t('Events.training.formContactHeadline')}</span>
					</HeadlineTag>
					<div className="!mt-4 grid gap-x-5 gap-y-4 md:grid-cols-2">
						<TextField
							label={t('Form.label.givenName')}
							htmlId={formName + 'givenName'}
							name="givenName"
							value={givenName}
							onChange={setGivenName}
							autoComplete="given-name"
							required
						/>
						<TextField
							label={t('Form.label.familyName')}
							htmlId={formName + 'familyName'}
							name="familyName"
							value={familyName}
							onChange={setFamilyName}
							autoComplete="family-name"
							required
						/>
						<TextField
							label={t('Form.label.email')}
							htmlId={formName + 'email'}
							name="email"
							value={email}
							onChange={setEmail}
							autoComplete="email"
							required
							type="email"
							className="col-start-1"
						/>
						<TextField
							label={t('Form.label.phone')}
							htmlId={formName + 'phone'}
							name="phone"
							value={phone}
							onChange={setPhone}
							autoComplete="tel"
							type="tel"
							required
						/>
						<div className="pt-6 sm:col-span-2">
							<Checkbox
								name="contactParticipates"
								htmlId={formName + 'contactParticipates'}
								label={t('Form.label.contactParticipates')}
								value={contactParticipates}
								onChange={(value) => toggleContactAsParticipant(value)}
							/>
						</div>
					</div>
					<HeadlineTag
						order={headlineOrder + 1}
						className="text-18 uppercase font-bold"
					>
						<span>{t('Events.training.formInvoiceHeadline')}</span>
					</HeadlineTag>
					<div className="!mt-4 grid gap-x-5 gap-y-4 md:grid-cols-2">
						<TextField
							label={t('Form.label.organization')}
							htmlId={formName + 'organization'}
							name="organization"
							value={organization}
							onChange={setOrganization}
							autoComplete="organization"
							required
						/>
						<TextField
							label={t('Form.label.address')}
							htmlId={formName + 'address'}
							name="address"
							value={address}
							onChange={setAddress}
							autoComplete="street-address"
							required={true}
							className="md:row-start-2"
						/>
						<TextField
							label={t('Form.label.addressAddition')}
							htmlId={formName + 'addressAddition'}
							name="addressAddition"
							value={addressAddition}
							onChange={setAddressAddition}
							className="md:row-start-2"
						/>
						<TextField
							label={t('Form.label.postalCode')}
							htmlId={formName + 'postalCode'}
							name="postalCode"
							value={postalCode}
							onChange={setPostalCode}
							autoComplete="postal-code"
							required={true}
							className="md:row-start-3"
						/>
						<TextField
							label={t('Form.label.city')}
							htmlId={formName + 'city'}
							name="city"
							value={city}
							onChange={setCity}
							autoComplete="address-level2"
							required={true}
							className="md:row-start-3"
						/>
					</div>
					<div className="space-y-5">
						<HeadlineTag
							order={headlineOrder + 1}
							className="text-18 uppercase font-bold"
						>
							<span>{t('Events.training.formParticipantsHeadline')}</span>
						</HeadlineTag>
						{contactParticipates && (
							<div className="flex items-end">
								<TextField
									label={t('Form.label.contactParticipatesInput')}
									htmlId={formName + 'contactParticipantName'}
									name="contactParticipantName"
									value={`${givenName} ${familyName}`}
									autoComplete="off"
									readOnly
									className="flex-1"
								/>
								<TextField
									htmlId={formName + 'contactParticipantEmail'}
									name="contactParticipantEmail"
									value={`${email}`}
									autoComplete="off"
									readOnly
									className="flex-1"
								/>
								<button
									type="button"
									className="btn-tertiary btn-notext w-10 h-10 bg-gray-400"
									onClick={() => toggleContactAsParticipant(false)}
								>
									<span className='b-icon-single'>
										<ClearIcon />
										<ClearIcon />
									</span>
								</button>
							</div>
						)}
						{participants.map((participant) => (
							<div key={participant.id} className="flex items-end">
								<TextField
									label={t('Form.label.participantName')}
									htmlId={formName + 'participantName' + participant.id}
									name={'participantName' + participant.id}
									autoComplete="off"
									value={participant.name}
									onChange={(value) =>
										changeParticipant(participant.id, {
											name: 'name',
											value,
										})
									}
									required
									className="flex-1"
								/>
								<TextField
									label={t('Form.label.participantEmail')}
									htmlId={formName + 'participantEmail' + participant.id}
									name={'participantEmail' + participant.id}
									type="email"
									autoComplete="off"
									value={participant.email}
									onChange={(value) =>
										changeParticipant(participant.id, {
											name: 'email',
											value,
										})
									}
									required
									className="flex-1"
								/>
								<button
									type="button"
									className="btn-tertiary btn-notext w-10 h-10 bg-gray-400"
									onClick={() => deleteParticipant(participant.id)}
								>
									<span className='b-icon-single'>
										<ClearIcon />
										<ClearIcon />
									</span>
								</button>
							</div>
						))}
						<div className="!mt-10">
							<Button
								as="button"
								variant="secondary"
								onClick={() => addParticipant()}
								Icon={Add}
							>
								{t('Events.training.addParticipant')}
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="min-w-0 space-y-10 lg:col-span-12 bg-off-white p-10 lg:-mx-10">
				<HeadlineTag
					className={cn(
						'max-w-2xl text-24 leading-1.2 font-bold uppercase break-words md:text-32',
					)}
					order={headlineOrder + 1}
				>
					<span>{t('Events.training.summaryHeadline')}</span>
				</HeadlineTag>
				<div className="grid gap-x-5 md:gap-y-4 md:grid-cols-[max-content_1fr]">
					<div>{t('Events.training.summaryDateTitle')}</div>
					<div className="font-bold">
						{trainingDate
							? trainingDate
							: t('Events.training.summaryNoDateSelected')}
					</div>
					<div className="mdmax:mt-4">
						{t('Events.training.summaryTotalParticipantsTitle')}
					</div>
					<div className="font-bold">
						{t('Events.training.summaryTotalParticipants', {
							count: totalParticipants || 0,
						})}
					</div>
					<div className="mdmax:mt-4">
						{t('Events.training.summaryCostsTitle')}
					</div>
					<div className="font-bold">
						{t('Events.training.summaryCosts', {
							costs: costsFormatted,
							count: costs,
						})}
					</div>
				</div>
				<div className="">
					<Checkbox
						name="privacypolicy"
						htmlId={formName + 'privacypolicy'}
						required
						label={terms}
					/>
				</div>
				{recaptchaEnabled && (
					<div>
						<Recaptcha
							ref={recaptchaRef}
							onExpired={recaptchaOnExired}
							onErrored={recaptchaOnErrored}
							asyncScriptOnLoad={recaptchaAsyncScriptOnLoad}
							sitekey={recaptchaSiteKey}
							size="compact"
						/>
					</div>
				)}
				{errorMessage && (
					<RichText className="text-error">{errorMessage}</RichText>
				)}
				<div>
					<Button
						variant="primary"
						as="button"
						type="submit"
						className={cn(isPending && 'opacity-50')}
						showLinkIcon={true}
					>
						{submitLabel}
					</Button>
				</div>
				{pendingMessage && isPending && <RichText>{pendingMessage}</RichText>}
			</div>
		</form>
	)
}

FormTraining.defaultProps = {
	name: 'contact',
	headlineOrder: 2,
}

FormTraining.propTypes = {
	name: PropTypes.string,
	redirectUri: PropTypes.string,
}

export default FormTraining
