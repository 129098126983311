import _ from 'lodash'

// Transforms 01.02.2022 to 2022/02/01
export const transformStrapiDateString = (dateStr) => {
	return dateStr ? dateStr.split('.').reverse().join('/') : undefined
}

export const parseStrapiDate = (dateStr) => {
	return dateStr ? new Date(transformStrapiDateString(dateStr)) : undefined
}

const rangeOptions = { year: 'numeric', month: 'numeric', day: 'numeric' }
const rangeTimeOptions = {
	weekday: 'long',
	month: '2-digit',
	year: 'numeric',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
}

export const formatRange = ({ startDate, endDate, locale }) => {
	const defaultDate = new Date(0); // 1970-01-01T00:00:00.000Z

	console.log("formatRange", "startDate:" + startDate, "endDate:" + endDate);

	if (!_.isDate(startDate)) return null;

	const format = new Intl.DateTimeFormat(locale, rangeOptions)
	const formattedDate = _.isDate(endDate)
		? format.formatRange(startDate, endDate)
		: format.format(startDate)
	return formattedDate
};

export const formatRangeWithTime = ({ startDate, endDate, locale }) => {

	console.log("formatRangeWithTime", "startDate:" + startDate, "endDate:" + endDate);

	if ((startDate))

		if (!_.isDate(startDate)) return null;

	const format = new Intl.DateTimeFormat(locale, rangeTimeOptions)
	const formattedDate = _.isDate(endDate)
		? format.formatRange(startDate, endDate)
		: format.format(startDate)
	return formattedDate
};